import { atom, useAtom } from 'jotai'
import { atomEffect } from 'jotai-effect'

import { ActorOverviewFragment, AdminDetailFragment, AdminManagerDetailFragment, AdminOverviewFragment, AppointmentDetailFragment, AppointmentTypeOverviewFragment, DepartmentOverviewFragment, ManagerDetailFragment, ManagerOverviewFragment, PracticeDetailFragment, PracticeOverviewFragment, ProviderDetailFragment, ProviderOverviewFragment } from '@docpace/shared-graphql/fragments'
import { SelectedAppointmentAction } from '@docpace/shared-ts-types/base/Appointment'
import { atomWithReset } from 'jotai/utils'

export const ctxPracticeId = atomWithReset<string | undefined>(undefined)
export const ctxPracticeOverview = atomWithReset<PracticeOverviewFragment | undefined>(undefined)
export const ctxPracticeProviders = atomWithReset<ProviderDetailFragment[]>([])
export const ctxPracticeDepartments = atomWithReset<DepartmentOverviewFragment[]>([])
export const ctxPracticeAppointmentsTypes = atomWithReset<AppointmentTypeOverviewFragment[]>([])
export const ctxActorId = atomWithReset<string | undefined>(undefined)
export const ctxActor = atomWithReset<ActorOverviewFragment | undefined>(undefined)
export const ctxAdminId = atomWithReset<string | undefined>(undefined)
export const ctxAdmin = atomWithReset<AdminDetailFragment | undefined>(undefined)
export const ctxManagerId = atomWithReset<string | undefined>(undefined)
export const ctxManager = atomWithReset<ManagerDetailFragment | AdminManagerDetailFragment | undefined>(undefined)

export const ctxSelectedPracticeIds = atom<string[]>([])
export const ctxSelectedProviderIds = atom<string[]>([])
export const ctxSelectedDepartmentIds = atom<string[]>([])
export const ctxSelectedAppointmentTypeIds = atom<string[]>([])
export const ctxSelectedAppointmentIds = atom<string[]>([])
export const ctxSelectedAppointmentAction = atomWithReset<SelectedAppointmentAction>(SelectedAppointmentAction.CLOSED)
export const ctxGetOnlySelectedAppointmentId = atom((get, set)=>{
    return get(ctxSelectedAppointmentIds)?.length === 1 ? get(ctxSelectedAppointmentIds)?.[0] : null
})

export const sharedAtomsPracticeReset = atomEffect((get, set)=>{
    // reset everything when the practice id changes
    // include this in the admin and manager layouts
    const practiceId = get(ctxPracticeId)
    set(ctxSelectedProviderIds, [])
    set(ctxSelectedDepartmentIds, [])
    set(ctxSelectedAppointmentIds, [])
    set(ctxSelectedAppointmentAction, SelectedAppointmentAction.CLOSED)
})
